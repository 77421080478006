
/* 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hero {
  display: inline-block;
  position: relative;
  width: 400px;
  min-width: 400px;
  height: 400px;
  border-radius: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  margin: 30px;
}

.hero-profile-img {
  height: 70%;
}

.hero-description-bk {
  background-image: linear-gradient(0deg , #3f5efb, #fc466b);
  border-radius: 30px;
  position: absolute;
  top: 55%;
  left: -5px;
  height: 65%;
  width: 108%;
  transform: skew(19deg, -9deg);
}

.second .hero-description-bk {
  background-image: linear-gradient(-20deg , #bb7413, #e7d25c)
}

.hero-logo {
  height: 80px;
  width: 80px;
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  bottom: 30%;
  left: 30px;
  overflow:hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.7);
}

.hero-logo img {
  height: 100%;
}

.hero-description {
  position: absolute;
  color: #fff;
  font-weight: 900;
  left: 150px;
  bottom: 26%;
}

.hero-btn {
  position: absolute;
  color: #fff;
  right: 30px;
  bottom: 10%;
  padding: 10px 20px;
  border: 1px solid #fff;
}

.hero-btn a {
  color: #fff;
}

.hero-date {
  position: absolute;
  color: #fff;
  left: 30px;
  bottom: 10%;
}


.tagcontainer {
  display: flex;  
  width: 100%;
  max-width: 100%;
  padding-left: 14px;
  border-radius: 5px;
  margin-top: 10px;
  align-items: center;
}

.tagiconcontainer {
  overflow: hidden;
  padding-left: 14px;
  background-color: #ffeb3b17;

  align-items: center;
  padding: 5px;
  border-top-left-radius: 5px;        
  border-bottom-left-radius: 5px;
}

.tagcontainer input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-top-right-radius: 5px;        
  border-bottom-right-radius: 5px;
  padding: 8px;
  padding-left: 14px;  
  background-color: #ffeb3b17;
  
}


textarea:focus, input:focus{
  outline: none;
}

.tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid orange;
  border-radius: 5px;
  background-color: orange;
  white-space: nowrap;
  color: #fff;
}

.tag button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: #fff;
}
 */

 @media only screen and (max-width: 767px) {
  table.dnxTable {
    border: none !important;
  }
  
  table.dnxTable th,
  table.dnxTable td {
    padding: 1em 0.5em;
    position: relative;
  }

  table.dnxTable tr,
  table.dnxTable td,
  table.dnxTable tfoot {
    display: block !important;
    width: 100%;
  }

  table.denxTable tbody tr {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  }

  table.denxTable tfoot tr {
    border: none !important;
  }

  table.dnxTable td {
    border-top: none !important;
    border-left: none !important;
  }
  
  table.dnxTable tbody tr {
    padding: 0.25em 0;
    border: 1px solid rgba(34, 36, 38, 0.2);
  }

  table.dnxTable tbody tr:not(:last-child) {
    margin-bottom: 0.25em;
  }

  /** important1: hide theader **/
  table.dnxTable thead {
    display: none !important;
  }

  table.dnxTable tbody td {
    padding: 0.3em 0.5em 0.3em 7.5em !important;
  }

  .dnxTable td:not(:last-child) {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  /** important2: use data-label attribute as a table header **/
  table.dnxTable tbody td:before {
    content: attr(data-label);
    left: 0.5em;
    position: absolute;
    top: 0.15em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 6em;
    overflow: hidden;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: default;
}

.tooltip .tooltiptext {
  visibility: hidden;
  padding: 0.25em 0.5em;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.25em;
  white-space: nowrap;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 100%;
  transition-property: visibility;
  transition-delay: 0s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  transition-delay: 0.3s;
}

 @tailwind base;
 @tailwind components;
 @tailwind utilities;
 